<template>
    <div v-if="currentTeacher" class="card">
        <b-form @submit="updateTeacher">

            <b-form-group id="firstname" label="Nome" label-for="firstname" description="">
                <b-form-input v-model="currentTeacher.firstname" placeholder=""></b-form-input>
            </b-form-group>

            <b-form-group id="lastname" label="Cognome" label-for="lastname" description="">
                <b-form-input v-model="currentTeacher.lastname" placeholder=""></b-form-input>
            </b-form-group>

            <b-form-group id="email" label="Email" label-for="email" description="">
                <b-form-input v-model="currentTeacher.email" placeholder="" type="email" disabled></b-form-input>
            </b-form-group>

            <b-form-group id="password" label="Password" label-for="password" description="Compila il campo solo se vuoi cambiare la password dell'utente, oppure lascialo vuoto.">
                <b-form-input v-model="currentTeacher.password" placeholder="" type="password"></b-form-input>
            </b-form-group>

            Attivo: <toggle-button v-model="currentTeacher.status" :height="25"/>
        </b-form>

        <div class="action-bar row">
            <div class="col-sm-8">
                <button class="btn btn-success " @click="updateTeacher" :disabled="this.$store.state.loading"><b-icon icon="file-earmark-check"/> Salva</button>
            </div>
            <div class="col-sm-4 text-right">
                <b-button variant="outline-danger"
                          v-if="(hasRole('admin') || hasRole('owner'))"
                          @click="removeTeacher"
                          :disabled="this.$store.state.loading"> <b-icon icon="archive"/> Rimuovi dal ruolo</b-button>
            </div>
        </div>
        <div>
            <br>
            <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
            <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
        </div>
    </div>

    <div v-else>
        <br/>
        <p>{{ (errorMsg) ? errorMsg : 'Please click on a Teacher...' }}</p>
    </div>
</template>

<script>
import TeacherDataService from "./TeacherDataService";
import ProfileDataService from "@/components/profile/ProfileDataService";

export default {
    name: "teacher-edit",
    data() {
        return {
            currentTeacher: null,
            message: '',
            errorMsg: ''
        };
    },
    props: ["teacherId"],
    methods: {
        getTeacher(id) {
            TeacherDataService.get(id)
                .then(response => {
                    this.currentTeacher = response.data;
                    this.currentTeacher.email = response.data.user.email;
                    this.currentTeacher.status = (this.currentTeacher.status == 1);
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });
        },

        updateTeacher() {
            TeacherDataService.update(this.currentTeacher.id, this.currentTeacher)
                .then(response => {
                    this.message = 'Informazioni salvate con successo!';
                    this.$emit('updated-teacher', this.currentTeacher);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        removeTeacher() {
            if(confirm(`Vuoi rimuovere l'insegnante ${this.currentTeacher.firstname} ${this.currentTeacher.lastname}? Il profilo sarà comunque disponibile`)) {
                ProfileDataService.removeRole(this.currentTeacher.id, 'teacher')
                    .then(response => {
                        this.$emit('deleted-teacher');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        },

        archiveTeacher() {
            if(confirm(`Vuoi archiviare l'insegnante ${this.currentTeacher.firstname} ${this.currentTeacher.lastname}?`)) {
                TeacherDataService.archive(this.currentTeacher.id)
                    .then(response => {
                        this.$emit('deleted-teacher');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        },

        deleteTeacher() {
            if(confirm(`Vuoi cancellare l'insegnante ${this.currentTeacher.firstname} ${this.currentTeacher.lastname}?`)) {
                TeacherDataService.delete(this.currentTeacher.id)
                    .then(response => {
                        this.$emit('deleted-teacher');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        },

        closeEditor() {
            this.$emit('closed-editor');
        }
    },
    mounted() {
        this.message = '';
        this.getTeacher(this.teacherId);
    }
};
</script>

<style>
.action-bar {
    margin-top: 15px;
}
</style>
