<template>
    <div class="card">
        <h1>Aggiungi un insegnante</h1>
        <div>
            <p>Aggiungi un utente già presente nella tua scuola con un altro ruolo</p>
            <profile-add role="teacher"
                         v-on:added-profile="addedTeacher()"
                         v-on:closed-editor="closeEditor()" />
        </div>
        <hr>
        <div v-if="!submitted">
            <h5>Crea un nuovo profilo</h5>
            <b-form @submit.stop.prevent="saveTeacher">

                <b-form-group id="email" label="Email" label-for="email" description="" @change="resetTeacher()">
                    <b-form-input
                        v-model="teacher.email"
                        placeholder=""
                        type="email"
                        :state="validateState('email')"
                        aria-describedby="email-live-feedback"></b-form-input>
                    <b-form-invalid-feedback
                        id="email-live-feedback"
                    >Questo campo è obbligatorio e deve essere un'email valida.</b-form-invalid-feedback>
                </b-form-group>

                <div v-if="state == 'ProfileFound'">
                    <p>Abbiamo trovato uno profilo associato a questa email nel nostro portale<br/>
                        Clicca sul bottone <b>aggiungi</b> per inserirlo nella tua scuola.<br/>
                        <b>L'utente riceverà una mail per confermare l'iscrizione</b>.</p>
                    <profile-found-view :profile="teacher" />
                </div>

                <div v-if="state == 'ProfileFoundInSchoolNotConfirmed'">
                    <p>Il profilo è già stato aggiunto come insegnante nella tua scuola, ma non ha ancora confermato.<br/>
                        Clicca sul bottone <b>Re invia email</b> per inviare di nuovo la mail di conferma.</p>
                    <profile-found-view :profile="teacher" />
                </div>

                <div v-if="state == 'ProfileFoundInSchool'">
                    <p><b>{{ teacher.firstname }} {{ teacher.lastname }}</b> è già presente nella scuola con un altro ruolo, clicca il bottone per aggiungerlo a questo ruolo.</p>
                </div>

                <div v-if="state == 'ProfileNotFound'">
                    <b-form-group id="firstname" label="Nome" label-for="firstname" description="">
                        <b-form-input
                            v-model="teacher.firstname"
                            placeholder=""
                            :state="validateState('firstname')"
                            aria-describedby="firstname-live-feedback"></b-form-input>
                        <b-form-invalid-feedback
                            id="firstname-live-feedback"
                        >Questo campo è obbligatorio e deve contenere almeno 3 caratteri.</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group id="lastname" label="Cognome" label-for="lastname" description="">
                        <b-form-input
                            v-model="teacher.lastname"
                            placeholder=""
                            :state="validateState('lastname')"
                            aria-describedby="lastname-live-feedback"></b-form-input>
                        <b-form-invalid-feedback
                            id="lastname-live-feedback"
                        >Questo campo è obbligatorio e deve contenere almeno 3 caratteri.</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group id="password" label="Password" label-for="password" description="">
                        <b-form-input
                            v-model="teacher.password"
                            placeholder=""
                            type="text"
                            :state="validateState('password')"
                            aria-describedby="password-live-feedback"></b-form-input>
                        <b-form-invalid-feedback
                            id="password-live-feedback"
                        >Questo campo è obbligatorio.</b-form-invalid-feedback>
                    </b-form-group>

                    Confermato: <toggle-button v-model="teacher.confirmed" :height="25"/>

                </div>
            </b-form>

            <div class="action-bar row">
                <div class="col-sm-8">

                    <b-button variant="success" type="button" @click="findTeacher()" v-if="state == 'None'">Continua</b-button>
                    <b-button variant="success" type="button" @click="resendConfirmMail()" :disabled="this.$store.state.loading" v-if="state == 'ProfileFoundInSchoolNotConfirmed'">Re invia email</b-button>
                    <button class="btn btn-success " @click="saveTeacher" :disabled="this.$store.state.loading" v-if="state != 'None' && state != 'ProfileFoundInSchoolNotConfirmed'">Aggiungi</button>

                </div>
                <div class="col-sm-4 text-right">

                </div>
            </div>
            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>
        </div>

        <br>


    </div>
</template>

<script>
import TeacherDataService from "./TeacherDataService";
import { required, minLength, email } from 'vuelidate/lib/validators'
import ProfileAdd from "@/components/profile/ProfileAdd";
import ProfileDataService from "@/components/profile/ProfileDataService";
import ProfileFoundView from "@/components/profile/ProfileFoundView";

let successEvent = new Event('onSuccessMsg');

export default {
    name: "teacher-create",
    components: {ProfileAdd, ProfileFoundView},
    data() {
        return {
            teacher: {
                id: null,
                firstname: "",
                lastname: "",
                email: "",
                password: "",
                active: true,
                confirmed: false
            },
            state: 'None', // None, ProfileNotFound, ProfileFound, ProfileFoundInSchool
            message: '',
            errorMsg: '',
            submitted: false
        };
    },
    validations: {
        teacher: {
            firstname: {
                required,
                minLength: minLength(4)
            },
            lastname: {
                required,
                minLength: minLength(4)
            },
            email: {
                required,
                email
            },
            password: {
                required,
                minLength: minLength(4)
            }
        }
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.teacher[name];
            return $dirty ? !$error : null;
        },
        saveTeacher() {
            switch (this.state) {
                case 'ProfileNotFound':
                    this.createTeacher();
                    break;
                case 'ProfileFoundInSchool':
                    this.addTeacher();
                    break;
                case 'ProfileFound':
                    this.sendRequestTeacher();
                    break;
                default:
                    break;
            }
        },

        createTeacher() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitted = false;
            } else {
                TeacherDataService.create(this.teacher)
                    .then(response => {
                        if (this.teacher.confirmed) successEvent.message = "L'utente è stato aggiunto alla tua scuola";
                        else successEvent.message = "Abbiamo inviato l'email di conferma all'indirizzo indicato. Quando l'utente completerà la procedura, potrai vederlo nell'elenco.";
                        document.dispatchEvent(successEvent);
                        this.teacher.id = response.data.id;
                        this.submitted = true;
                        this.$emit('created-teacher');
                    })
                    .catch(e => {
                        this.errorMsg = 'Non hai compilato tutti i campi';
                        console.log(e);
                    });
            }
        },
        addTeacher() {
            ProfileDataService.addRole(this.teacher.id, 'teacher', this.$store.state.schoolId)
                .then(response => {
                    this.submitted = true;
                    this.$emit('created-teacher');
                })
                .catch(e => {
                    this.errorMsg = 'Non hai compilato tutti i campi';
                    console.log(e);
                });
        },
        sendRequestTeacher() {
            ProfileDataService.addRole(this.teacher.id, 'teacher', this.$store.state.schoolId, false)
                .then(response => {
                    successEvent.message = "Abbiamo inviato l'email di conferma all'indirizzo indicato. Quando l'utente completerà la procedura, potrai vederlo nell'elenco.";
                    document.dispatchEvent(successEvent);
                    this.submitted = true;
                    this.$emit('created-teacher');
                })
                .catch(e => {
                    this.errorMsg = 'Non hai compilato tutti i campi';
                    console.log(e);
                });
        },
        resendConfirmMail() {
            ProfileDataService.resendConfirmMail(this.teacher.id, 'teacher', this.$store.state.schoolId)
                .then(response => {
                    successEvent.message = "Abbiamo inviato l'email di conferma all'indirizzo indicato. Quando l'utente completerà la procedura, potrai vederlo nell'elenco.";
                    document.dispatchEvent(successEvent);
                    this.submitted = true;
                    this.$emit('created-teacher');
                })
                .catch(e => {
                    this.errorMsg = 'Non hai compilato tutti i campi';
                    console.log(e);
                });
        },
        findTeacher() {
            const schoolId = this.$store.state.schoolId;
            ProfileDataService.getByEmail(this.teacher.email)
                .then(response => {
                    this.state = 'ProfileFound';
                    // if teacher is already added to current school return
                    if (response.data.schools) {
                        if (response.data.schools.find(s => s.id == schoolId && s.profile_school.role == 'teacher' && s.confirmed)) {
                            this.state = 'None';
                            alert('l\'insegnante è già presente nella tua scuola');
                            return;
                        }
                        if (response.data.schools.find(s => s.id == schoolId && s.profile_school.role == 'teacher' && !s.confirmed)) {
                            this.state = 'ProfileFoundInSchoolNotConfirmed';
                        } else if (response.data.schools.find(s => s.id == schoolId)) {
                            this.state = 'ProfileFoundInSchool';
                        }
                    }
                    // otherwise continue
                    this.teacher.id = response.data.id;
                    this.teacher.firstname = response.data.firstname;
                    this.teacher.lastname = response.data.lastname;
                    this.teacher.birthDate = response.data.birthDate;
                    this.teacher.active = response.data.active;
                })
                .catch(e => {
                    if (e.response.status == 400) this.state = 'ProfileNotFound';
                    else this.errorMsg = 'Non hai compilato tutti i campi';
                    console.log(e.response);
                });
        },
        resetTeacher() {
            this.teacher.id = null;
            this.teacher.firstname = "";
            this.teacher.lastname = "";
            this.teacher.birthDate = "";
            this.teacher.active = true;
            this.state = 'None';
        },
        addedTeacher() {
            this.$emit('created-teacher');
        },

        closeEditor() {
            this.$emit('closed-editor');
        },

        newTeacher() {
            this.submitted = false;
            this.teacher = {};
        },
    },
    watch: {
        'teacher.email': function (value) {
            if (value == '') this.resetTeacher();
        },
    }
};
</script>

<style>
.submit-form {
    max-width: 300px;
    margin: auto;
}
</style>
