<template>
    <div>
        <div class="row section-header" >
            <div class="col-md-3 section-title">
                <h3>Insegnanti</h3>
            </div>

            <div class="col-md-6">
                <search-bar @search-name="searchName($event)"/>
            </div>

            <div class="col-md-3 text-right" style="">
            </div>
        </div>

        <div class="top-content ">
            <button class="btn btn-primary btn-add" @click="currentId = 0; currentTeacher = null">
                <b-icon icon="plus"/> Aggiungi Insegnante
            </button>
        </div>

        <div class="content">
            <div class="index" :class="{ 'opened': (currentId == -1) }">
                <div class="card">
                    <table class="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col" width="40"></th>
                            <th scope="col">Nome</th>
                            <th scope="col" class="text-center">Stato</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :class="{ 'table-primary': teacher.id == currentId }"
                            v-for="teacher in teachers"
                            :key="teacher.id"
                            @click="setActiveTeacher(teacher)" >
                            <td><img src="../../assets/user-Icon.jpg" class="rounded-circle" style="width: 30px"></td>
                            <td>{{ teacher.firstname }} {{ teacher.lastname }}</td>
                            <td :class="{ 'text-success': (teacher.status), 'text-muted': !teacher.status }" class="text-center">
                                <b-icon :icon="(teacher.status) ? 'check2' : 'x-circle'"/></td>
                        </tr>
                        </tbody>
                    </table>
                    <p v-if="this.teachers.length == 0">Ancora nessun insegnante inserito</p>
                </div>
            </div>

            <div class="editor" :class="{ 'opened': (currentId >= 0) }">
                <div class="top-editor">
                    <b-button variant="link" @click="closeEditor"  :disabled="this.$store.state.loading"><b-icon icon="x"/> Chiudi</b-button>
                </div>
                <div v-if="currentId > 0">
                    <teacher-edit :teacher-id="currentTeacher.id" :key="currentTeacher.id"
                                  v-on:updated-teacher="refreshCurrentTeacher($event)"
                                  v-on:deleted-teacher="refreshList()"
                                  v-on:closed-editor="closeEditor()"/>
                </div>
                <div v-else-if="currentId == 0">
                    <teacher-create
                        v-on:created-teacher="refreshList()"
                        v-on:closed-editor="closeEditor()"/>
                </div>
            </div>

        </div>




    </div>

</template>

<script>
import TeacherDataService from "./TeacherDataService";
import TeacherEdit from "@/components/teacher/TeacherEdit";
import TeacherCreate from "@/components/teacher/TeacherCreate";
import SearchBar from "@/components/layout/SearchBar";

export default {
    name: "teachers-index",
    components: {TeacherEdit, TeacherCreate, SearchBar},
    data() {
        return {
            teachers: [],
            currentTeacher: null,
            currentId: -1
        };
    },
    methods: {
        retrieveTeachers() {
            TeacherDataService.getAll()
                .then(response => {
                    this.teachers = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        refreshList() {
            this.retrieveTeachers();
            this.currentTeacher = null;
            this.currentId = -1;
        },

        refreshCurrentTeacher(teacher) {
            this.teachers.forEach(function (item) {
                if (item.id == teacher.id) {
                    item.firstname = teacher.firstname;
                    item.lastname = teacher.lastname;
                    item.status = teacher.status;
                }
            });
            this.closeEditor();
            //this.currentTeacher = teacher;
            //this.currentId = teacher.id;
        },

        setActiveTeacher(teacher) {
            this.currentTeacher = teacher;
            this.currentId = teacher.id;
        },

        removeAllTeachers() {
            TeacherDataService.deleteAll()
                .then(response => {
                    this.refreshList();
                })
                .catch(e => {
                    console.log(e);
                });
        },

        searchName(name) {
            this.currentTeacher = null;
            this.currentId = -1;
            TeacherDataService.findByName(name)
                .then(response => {
                    this.teachers = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        closeEditor() {
            this.currentTeacher = null;
            this.currentId = -1;
        }
    },
    mounted() {
        this.retrieveTeachers();
    }
};
</script>

<style>
</style>
